/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 23/07/2017
 * Time: 12:15
 */
import * as types from '../constants/actionTypes'

const defaultState = {
    sending: false,
    message: ""
}

function contact(state = defaultState, action) {
    switch (action.type) {

        case types.REQUEST_SEND_CONTACT:
            return {
                ...state,
                sending:true
            }

        case types.SEND_CONTACT_SUCCESS:
            return {
                ...state,
                sending:false,
                message: action.message
            }

        case types.SEND_CONTACT_ERROR:
            return {
                ...state,
                sending:false,
                message: action.message
            }


        default:
            return state;
    }
}

export default contact
