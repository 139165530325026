/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 18/04/2017
 * Time: 17:37
 */

export const SERVER_URL = 'https://digitalawesome.herokuapp.com';
// https://digitalawesome.herokuapp.com;
// http://localhost:4000;
