/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 09/07/2017
 * Time: 17:06
 */
import React from 'react';
import Rodal from 'rodal';
import { DownloadCloud } from 'react-feather';

const Thankyou = (props) => {
    const { toggleThankyou, cart, products } = props;
    return (
        <div>
            <Rodal
                visible={cart.thankyouOpen}
                onClose={() => toggleThankyou(false)}
                width={640}
                height={340}
                customStyles={{ borderRadius: 10 }}
                customMaskStyles={{
                    backgroundColor: 'rgb(24, 39, 56)',
                    opacity: 0.85
                }}
            >
                <div>
                    <div className="thankyou">
                        <div className="thankyou-header">
                            <h1>Thank You!</h1>
                        </div>
                        <div className="thankyou-content text-center">
                            <h2>Your product is ready for download</h2>
                            <a href={products.product.link}>
                                <button type="button" className="btn btn-full">
                                    Download
                                    {' '}
                                    <DownloadCloud
                                        style={{ marginBottom: -5 }}
                                    />
                                </button>
                            </a>
                            <p>
                                We sent you an email with a link for future
                                downloads.
                            </p>
                        </div>
                        <div className="thankyou-footer text-center">
                            <p>hello@digitalaweso.me</p>
                            <h5>Digital Awesome Team</h5>
                        </div>
                    </div>
                </div>
            </Rodal>
        </div>
    );
};

export default Thankyou;
