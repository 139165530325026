/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 06/07/2017
 * Time: 14:49
 */
import React from 'react';
import { Link } from 'react-router-dom';

class Header extends React.Component {
    constructor() {
        super();

        this.state = {
            menuOpen: false,
            navlinks: [{ name: 'Home', url: '/' }]
        };

        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu() {
        this.setState({
            menuOpen: !this.state.menuOpen
        });
    }

    render() {
        return (
            <div>
                <div className="header">
                    <div className="container">
                        <div className="flex-wrapper">
                            <div className="navbar-wrapper">
                                <div className="navbar-inner row between-xs middle-xs">
                                    <Link className="logo" to="/">
                                        <img
                                            src="/images/logo.svg"
                                            alt="Digital Awesoem"
                                        />
                                    </Link>

                                    <div
                                        className={
                                            this.state.menuOpen
                                                ? 'hamburger is-active'
                                                : 'hamburger'
                                        }
                                        onClick={this.toggleMenu}
                                    >
                                        <div className="hamburger-box">
                                            <div className="hamburger-inner" />
                                        </div>
                                    </div>
                                </div>

                                <ul
                                    className={
                                        this.state.menuOpen
                                            ? 'navbar-menu open'
                                            : 'navbar-menu'
                                    }
                                >
                                    {this.state.navlinks.map((link, index) => (
                                        <li key={index}>
                                            <Link to={link.url}>
                                                {link.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <Link className="btn contact-btn" to="/contact">
                                Contact
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;
