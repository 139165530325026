/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 09/07/2017
 * Time: 17:19
 */
import * as types from '../constants/actionTypes';

const defaultState = {
    thankyouOpen: false,
    enterEmailOpen: false,
    isLoading: false
};

function cart(state = defaultState, action) {
    switch (action.type) {
        case types.REQUEST_CHECKOUT:
            return {
                ...state,
                isLoading: true,
                enterEmailOpen: false
            };

        case types.CHECKOUT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                thankyouOpen: true
            };

        case types.CHECKOUT_ERROR:
            return {
                ...state,
                isLoading: false
            };

        case types.TOGGLE_THANKYOU:
            return {
                ...state,
                thankyouOpen: action.isOpen
            };

        case types.TOGGLE_ENTER_EMAIL:
            return {
                ...state,
                enterEmailOpen: action.isOpen
            };

        case types.TOGGLE_IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };

        default:
            return state;
    }
}

export default cart;
