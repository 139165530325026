/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 10/08/2017
 * Time: 8:19
 */

import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import helpers from './helpers';

const analytics = {
    initiateCheckout(product) {
        ReactGA.event({
            category: 'Checkout',
            action: 'Attempt',
            label: product.name
        });

        ReactPixel.track('InitiateCheckout', {
            product_name: product.name,
            content_category: product.categories[0],
            content_id: product.id,
            price: product.price,
            currency: 'USD'
        });
    },

    purchase(product) {
        // Facebook
        ReactPixel.track('Purchase', {
            product_name: product.name,
            content_category: product.categories[0],
            content_id: product.id,
            value: product.price,
            currency: 'USD'
        });

        // GA
        ReactGA.event({
            category: 'Checkout',
            action: 'Purchase',
            label: product.name,
            value: product.price
        });

        // GA Ecommerce
        ReactGA.plugin.execute('ecommerce', 'addItem', {
            id: product.id,
            name: product.name,
            sku: product.slug,
            category: product.categories[0],
            price: product.price,
            quantity: '1'
        });

        ReactGA.plugin.execute('ecommerce', 'addTransaction', {
            id: helpers.guid(),
            name: product.name,
            sku: product.slug,
            category: product.categories[0],
            revenue: product.price
        });

        ReactGA.plugin.execute('ecommerce', 'send');
        ReactGA.plugin.execute('ecommerce', 'clear');
    }
};

export default analytics;
