/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 23/07/2017
 * Time: 11:42
 */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../actions';

class Contact extends React.Component {
    constructor() {
        super();

        this.state = {
            name: '',
            email: '',
            message: '',
            notice: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            notice: newProps.contact.message
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        if (this.state.name === '') {
            this.setState({
                notice: 'You forgot to write your name'
            });
            return;
        }
        if (this.state.email === '') {
            this.setState({
                notice: 'You forgot to write your email'
            });
            return;
        }

        if (this.state.message === '') {
            this.setState({
                notice: 'You forgot to write your message'
            });
            return;
        }

        this.setState({
            name: '',
            email: '',
            message: ''
        });
        this.props.sendContactForm(this.state);
    }

    render() {
        return (
            <div className="contact">
                <div className="container">
                    <h1 className="text-center">Drop us a line...</h1>
                    <div className="row">
                        <form className="contact-form col-xs-12 col-sm-6 col-sm-offset-3">
                            <div className="row">
                                <div className="form-group col-xs-12 col-sm-6">
                                    <label htmlFor="name_input">Name</label>
                                    <input
                                        type="text"
                                        id="name_input"
                                        value={this.state.name}
                                        placeholder="Your name"
                                        onChange={(e) => {
                                            this.setState({
                                                name: e.target.value
                                            });
                                        }}
                                    />
                                </div>
                                <div className="form-group col-xs-12 col-sm-6">
                                    <label htmlFor="email_input">Email</label>
                                    <input
                                        type="email"
                                        id="email_input"
                                        value={this.state.email}
                                        placeholder="Your email"
                                        onChange={(e) => {
                                            this.setState({
                                                email: e.target.value
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-xs-12">
                                    <label htmlFor="message_input">
                                        Message
                                    </label>
                                    <textarea
                                        id="message_input"
                                        rows="10"
                                        value={this.state.message}
                                        placeholder="Write us a message..."
                                        onChange={(e) => {
                                            this.setState({
                                                message: e.target.value
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="text-center">
                                <p>{this.state.notice}</p>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 text-right">
                                    <button
                                        onClick={(e) => {
                                            this.handleSubmit(e);
                                        }}
                                        className="btn btn-full"
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    contact: state.contact
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Contact);
