/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 14/06/2016
 * Time: 15:22
 */
import React from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

// Import Styles
import './styles/main.scss';

// Import React Router Deps
import { Provider } from 'react-redux';
import Router from './config/Router';
import store from './store/configureStore';

// Google Analytics
ReactGA.initialize('UA-103653003-1');
ReactGA.plugin.require('ecommerce', { path: '/log', debug: true });

// Facebook Pixel
ReactPixel.init('160603734507121');

const App = () => (
    <Provider store={store}>
        <Router />
    </Provider>
);

export default App;
