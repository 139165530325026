/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 06/07/2017
 * Time: 14:49
 */
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
    <div className="footer">
        <div className="container">
            <div className="footer-content">
                <div className="copyrights">
                    <p>{`${new Date().getFullYear()} © Digital Awesome`}</p>
                </div>
                <div className="footer-text">
                    <Link to="/contact">Need anything? </Link>
                </div>
            </div>
        </div>
    </div>
);

export default Footer;
