/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 14/06/2016
 * Time: 15:35
 */
import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'rodal/lib/rodal.css';
import actions from '../actions';
import Header from './Partials/Header';
import Footer from './Partials/Footer';
import Spinner from './Partials/Spinner';

const Main = (props) => {
    const { location, cart, children } = props;
    const currentLocation = location.pathname;
    const { isLoading } = cart;
    const isPreview = currentLocation.indexOf('preview') >= 0;

    return (
        <div className="main-container">
            {!isPreview && <Header {...props} />}
            <div className="content">{children}</div>
            {isLoading && <Spinner />}
            {!isPreview && <Footer {...props} />}
        </div>
    );
};

const mapStateToProps = state => ({
    cart: state.cart
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Main)
);
