/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 09/07/2017
 * Time: 3:13
 */
import React from 'react';
import { Link } from 'react-router-dom';
import { Tablet, Smartphone, Monitor } from 'react-feather';
import MediaQuery from 'react-responsive';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../actions';
import Checkout from '../Partials/Checkout';

class Preview extends React.Component {
    componentDidMount() {
        const { setSingleProduct, match } = this.props;
        setSingleProduct(match.params.slug);
    }

    render() {
        const { products, preview, setMedia } = this.props;
        const { product } = products;
        const { media } = preview;
        let frameWidth;
        switch (media) {
            case 'desktop':
                frameWidth = '100%';
                break;
            case 'tablet':
                frameWidth = 700;
                break;
            case 'mobile':
                frameWidth = 400;
                break;
            default:
                frameWidth = '100%';
                break;
        }

        const style = {
            iframe: {
                width: frameWidth
            }
        };
        return (
            <div className="preview">
                <header className="preview-header shadow-3">
                    <div className="container">
                        <div className="preview-header-content">
                            <span>
                                <Link to={`/product/${product.slug}`}>
                                    <button
                                        type="button"
                                        className="btn btn-black"
                                    >
                                        Details
                                    </button>
                                </Link>
                            </span>
                            <MediaQuery query="(min-width: 992px)">
                                <div className="media-picker">
                                    <button
                                        type="button"
                                        className={
                                            media === 'desktop' ? 'active' : ''
                                        }
                                        onClick={() => {
                                            setMedia('desktop');
                                        }}
                                    >
                                        <Monitor />
                                    </button>
                                    <button
                                        type="button"
                                        className={
                                            media === 'tablet' ? 'active' : ''
                                        }
                                        onClick={() => {
                                            setMedia('tablet');
                                        }}
                                    >
                                        <Tablet />
                                    </button>
                                    <button
                                        type="button"
                                        className={
                                            media === 'mobile' ? 'active' : ''
                                        }
                                        onClick={() => {
                                            setMedia('mobile');
                                        }}
                                    >
                                        <Smartphone style={{ width: 22 }} />
                                    </button>
                                </div>
                            </MediaQuery>
                            <div className="actions">
                                <Checkout {...this.props} />
                            </div>
                        </div>
                    </div>
                </header>
                <iframe
                    style={style.iframe}
                    src={product.preview}
                    title={product.name}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    products: state.products,
    preview: state.preview
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Preview);
