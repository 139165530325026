/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 09/07/2017
 * Time: 17:08
 */
import * as types from '../constants/actionTypes';
import analytics from '../utils/analytics';
import { post } from '../utils/apiCaller';

// ==================================================================
// CHECKOUT==========================================================
// ==================================================================

export function checkout(token, product) {
    return (dispatch) => {
        dispatch(requestCheckout());
        post('cart/checkout', { product, token })
            .then(() => {
                analytics.purchase(product);
                dispatch(checkoutSuccess());
            })
            .catch((error) => {
                console.log(error);
                dispatch(checkoutError());
            });
    };
}

function requestCheckout() {
    return {
        type: types.REQUEST_CHECKOUT
    };
}

function checkoutSuccess() {
    return {
        type: types.CHECKOUT_SUCCESS
    };
}

function checkoutError() {
    return {
        type: types.CHECKOUT_ERROR
    };
}

// ==================================================================
// TOGGLE THANK YOU==================================================
// ==================================================================

export function toggleThankyou(isOpen) {
    return {
        type: types.TOGGLE_THANKYOU,
        isOpen
    };
}

// ==================================================================
// TOGGLE ENTER EMAIL================================================
// ==================================================================

export function toggleEnterEmail(isOpen) {
    return {
        type: types.TOGGLE_ENTER_EMAIL,
        isOpen
    };
}
