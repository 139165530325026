/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 25/07/2016
 * Time: 12:26
 */
import * as products from './products';
import * as preview from './preview';
import * as cart from './cart';
import * as contact from './contact';

export default Object.assign({}, products, preview, cart, contact);
