/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 14/06/2016
 * Time: 15:23
 */
import React from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Main from '../components/Main';
import Home from '../components/Home';
import Single from '../components/Single/Single';
import Preview from '../components/Preview/Preview';
import Contact from '../components/Contact/Contact';

const logPageView = () => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);

    ReactPixel.pageView();
};

const AppRouter = props => (
    <Router onUpdate={logPageView}>
        <Main {...props}>
            <Route exact path="/" component={Home} />
            <Route path="/product/:slug" component={Single} />
            <Route path="/preview/:slug" component={Preview} />
            <Route path="/contact" component={Contact} />
        </Main>
    </Router>
);

export default AppRouter;
