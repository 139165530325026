/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 14/07/2017
 * Time: 8:18
 */
import React from 'react';

const ReactSpinner = require('react-spinkit');

class Spinner extends React.Component {
    render() {
        return (
            <div className="spinner-overlay">
                <div className="spinner">
                    <ReactSpinner
                        name="line-spin-fade-loader"
                        color="#fff"
                        fadeIn="none"
                    />
                </div>
            </div>
        );
    }
}

export default Spinner;
