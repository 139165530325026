/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 25/07/2016
 * Time: 13:38
 */

export const SET_SINGLE_PRODUCT = 'SET_SINGLE_PRODUCT';
export const SET_MEDIA = 'SET_MEDIA';
export const REQUEST_CHECKOUT = 'REQUEST_CHECKOUT';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
export const CHECKOUT_ERROR = 'CHECKOUT_ERROR';
export const TOGGLE_THANKYOU = 'TOGGLE_THANKYOU';
export const TOGGLE_ENTER_EMAIL = 'TOGGLE_ENTER_EMAIL';
export const REQUEST_SEND_CONTACT = 'REQUEST_SEND_CONTACT';
export const SEND_CONTACT_SUCCESS = 'SEND_CONTACT_SUCCESS';
export const SEND_CONTACT_ERROR = 'SEND_CONTACT_ERROR';
export const TOGGLE_IS_LOADING = 'TOGGLE_IS_LOADING';
