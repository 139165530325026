/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 27/08/2017
 * Time: 11:18
 */
export default [
    {
        id: '24769',
        image:
            'https://res.cloudinary.com/benzo/image/upload/v1550300857/digital-awesome/nova/nova.png',
        full_screen:
            'https://res.cloudinary.com/benzo/image/upload/v1550300857/digital-awesome/nova/nova_full.png',
        name: 'Nova',
        tagline: 'A free HTML template',
        description:
            'Nova is a clean Landing Page HTML template. Features include colorful intro section, feature overview, client/publicity logos, pricing table, team and ends with a newsletter sign up box.',
        slug: 'nova',
        preview: 'https://nova.webscopeapp.com',
        categories: ['templates'],
        docs: '',
        link: 'https://nova.webscopeapp.com/nova.zip',
        created_at: '2017-08-01',
        updated_at: '2017-08-01',
        price: 0,
        downloads: 324,
        support: 'Regular',
        is_ad: false
    },
    {
        id: '56294',
        image:
            'https://res.cloudinary.com/benzo/image/upload/v1550300857/digital-awesome/showcase/showcase.jpg',
        full_screen:
            'https://res.cloudinary.com/benzo/image/upload/v1550300857/digital-awesome/showcase/showcase_full.png',
        name: 'Showcase',
        tagline: 'A premium portfolio HTML template',
        description:
            'Showcase is a gorgeous two-page template for a '
            + 'personal portfolio website. It includes a grid of projects, a project page with lightbox images, and a fully functional contact form.',
        slug: 'showcase',
        preview: 'https://showcase-template.netlify.com/',
        categories: ['templates'],
        docs: 'https://showcase-template.netlify.com/documentation',
        link: 'https://github.com/benzomedia/showcase/archive/v1.0.zip',
        created_at: '2017-08-27',
        updated_at: '2017-08-27',
        price: 10,
        downloads: 74,
        support: 'Regular'
    },
    {
        id: '34872',
        image:
            'https://res.cloudinary.com/benzo/image/upload/v1550300956/digital-awesome/trumpet/trumpet.jpg',
        full_screen:
            'https://res.cloudinary.com/benzo/image/upload/v1550300960/digital-awesome/trumpet/trumpet_full.jpg',
        name: 'Trumpet',
        tagline: 'A slick premium HTML template',
        description:
            "Trumpet is a colorful, fully responsive, HTML template that leaves an impression. Some of its features include a dynamic projects section, a testimonials' carousel, a fully functional contact form, and Google Maps integration.",
        slug: 'trumpet',
        preview: 'https://trumpet-template.netlify.com',
        categories: ['templates'],
        docs: 'https://trumpet-template.netlify.com/documentation',
        link: 'https://github.com/benzomedia/trumpet/archive/v1.0.zip',
        created_at: '2017-08-01',
        updated_at: '2017-08-01',
        price: 10,
        downloads: 251,
        support: 'Regular',
        is_ad: false
    },
    {
        id: '92835',
        image:
            'https://res.cloudinary.com/benzo/image/upload/v1550300873/digital-awesome/scale/scale.jpg',
        full_screen:
            'https://res.cloudinary.com/benzo/image/upload/v1550300872/digital-awesome/scale/scale_full.jpg',
        name: 'Scale',
        tagline: 'A free HTML template',
        description:
            'Scale is a neat, masterfully designed HTML template. It includes a lightbox image gallery, lovely animations, and a video player.',
        slug: 'scale',
        preview: 'https://scale-template.netlify.com',
        categories: ['templates'],
        docs: 'https://scale-template.netlify.com/documentation',
        link: 'https://github.com/benzomedia/scale/archive/v1.0.zip',
        created_at: '2017-08-10',
        updated_at: '2017-08-10',
        price: 0,
        downloads: 56,
        support: 'Regular'
    }
];
