/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 23/07/2017
 * Time: 12:14
 */
import * as types from '../constants/actionTypes';
import { post } from '../utils/apiCaller';

// =================================================================================
// SEND CONTACT FORM================================================================
// =================================================================================

export const sendContactForm = data => (dispatch) => {
    dispatch(requestSendContact());
    dispatch(toggleIsLoading(true));
    post('user/contact', data)
        .then((response) => {
            dispatch(sendContactSuccess(response));
            dispatch(toggleIsLoading(false));
        })
        .catch((error) => {
            console.log(error);
            dispatch(sendContactError());
            dispatch(toggleIsLoading(false));
        });
};

function requestSendContact() {
    return {
        type: types.REQUEST_SEND_CONTACT
    };
}

function sendContactSuccess() {
    return {
        type: types.SEND_CONTACT_SUCCESS,
        message: 'Your message was sent successfuly'
    };
}

function sendContactError() {
    return {
        type: types.SEND_CONTACT_ERROR,
        message:
            'There was a problem sending your message, please try again later'
    };
}

function toggleIsLoading(isLoading) {
    return {
        type: types.TOGGLE_IS_LOADING,
        isLoading
    };
}
