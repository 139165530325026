/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 06/07/2017
 * Time: 18:01
 */
import * as types from '../constants/actionTypes';
import store from '../store/configureStore';

// ==================================================================
// SET SINGLE PRODUCT================================================
// ==================================================================
export function setSingleProduct(slug) {
    const state = store.getState();
    const products = state.products.products.filter(
        product => product.slug === slug
    );

    return {
        type: types.SET_SINGLE_PRODUCT,
        product: products[0] || {}
    };
}
