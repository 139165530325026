import React from 'react';

const Ad = ({ product }) => (
    <div className="product">
        <div className="product-image-wrapper">
            <a target="_blank" rel="noopener noreferrer" to={product.link}>
                <img
                    className="img-responsive"
                    src={product.image}
                    alt={product.name}
                />
            </a>
        </div>
        <div className="card-info">
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={product.link}
                alt={product.name}
            >
                <h3>
                    {product.name}
                    {' '}
                    <span className="price free">Promoted</span>
                </h3>
                <p>{product.tagline}</p>
            </a>
        </div>
    </div>
);

export default Ad;
