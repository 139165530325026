/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 06/07/2017
 * Time: 16:31
 */
import React from 'react';

const Hero = () => (
    <div className="hero">
        <div className="container">
            <div className="hero-content">
                <h1>Premium HTML Templates for Your Next Web Project.</h1>
            </div>
        </div>
    </div>
);

export default Hero;
