/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 06/07/2017
 * Time: 16:13
 */
import React from 'react';
import Product from './Product';
import Ad from './Ad';

const Products = ({ products }) => (
    <div className="products-div row center-xs">
        {products.map(product => (product.is_ad ? (
            <div key={product.slug} className="col-xs-12 col-sm-6 col-md-4">
                <Ad product={product} />
            </div>
        ) : (
            <div key={product.slug} className="col-xs-12 col-sm-6 col-md-4">
                <Product product={product} />
            </div>
        )))}
    </div>
);

export default Products;
