/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 09/07/2017
 * Time: 4:06
 */
import React from 'react';
import StripeCheckout from 'react-stripe-checkout';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../actions';
import Thankyou from './Thankyou';
import EnterEmail from './EnterEmail';
import analytics from '../../utils/analytics';

class Checkout extends React.Component {
    constructor() {
        super();

        this.onToken = this.onToken.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.registerEvent = this.registerEvent.bind(this);
    }

    onToken(token) {
        const { checkout, products } = this.props;
        checkout(token, products.product);
    }

    handleClick() {
        const { toggleEnterEmail } = this.props;
        this.registerEvent();
        toggleEnterEmail(true);
    }

    registerEvent() {
        const { products } = this.props;
        analytics.initiateCheckout(products.product);
    }

    render() {
        const { products } = this.props;
        const { product } = products;
        return (
            <div className="checkout">
                {product.price === 0 ? (
                    <button
                        className="btn btn-full"
                        type="button"
                        onClick={this.handleClick}
                    >
                        Download
                    </button>
                ) : (
                    <React.Fragment>
                        <StripeCheckout
                            name="Digital Awesome"
                            description={`${product.name} Premium Theme`}
                            image="https://s3.eu-west-2.amazonaws.com/digitalawesome/logobotcircle.png"
                            amount={product.price * 100}
                            currency="USD"
                            token={this.onToken}
                            stripeKey="pk_live_uglhmOERn96c7pCrQ92bZW2f"
                        >
                            <button
                                onClick={this.registerEvent}
                                className="btn btn-full"
                                type="button"
                            >
                                {`Buy Now・$${product.price}`}
                            </button>
                        </StripeCheckout>
                    </React.Fragment>
                )}
                <EnterEmail onToken={this.onToken} {...this.props} />
                <Thankyou {...this.props} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    cart: state.cart,
    products: state.products
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Checkout);
