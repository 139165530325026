/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 06/07/2017
 * Time: 17:43
 */
import * as types from '../constants/actionTypes'
import productsList from '../utils/products'

const defaultState = {
    products: productsList,
    product: {}
}

function products(state = defaultState, action) {
    switch (action.type) {

        case types.SET_SINGLE_PRODUCT:
            return {
                ...state,
                product: action.product
            }

        default:
            return state
    }
}

export default products