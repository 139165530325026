/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 25/07/2016
 * Time: 12:09
 */
import {combineReducers} from 'redux'
import {routerReducer} from 'react-router-redux'

import products from './products'
import preview from './preview'
import cart from './cart'
import contact from './contact'


const rootReducer = combineReducers({
    products,
    preview,
    cart,
    contact,
    routing: routerReducer
})


export default rootReducer