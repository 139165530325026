/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 06/07/2017
 * Time: 17:01
 */
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { Eye, FileText } from 'react-feather';

const Product = ({ product }) => (
    <div className="product">
        <div className="product-image-wrapper">
            <Link to={`/product/${product.slug}`}>
                <img
                    className="img-responsive"
                    src={product.image}
                    alt={product.name}
                />
            </Link>
            <div className="actions">
                <Link
                    className="squircle"
                    to={`/product/${product.slug}`}
                    data-tip="Details"
                >
                    <FileText />
                </Link>
                <Link
                    className="squircle"
                    to={`/preview/${product.slug}`}
                    data-tip="Preview"
                >
                    <Eye />
                </Link>
            </div>
        </div>
        <div className="card-info">
            <Link to={`/product/${product.slug}`}>
                {product.price === 0 ? (
                    <h3>
                        {product.name}
                        {' '}
                        <span className="price free">Free</span>
                    </h3>
                ) : (
                    <h3>
                        {product.name}
                        {' '}
                        <span className="price">{`$${product.price}`}</span>
                    </h3>
                )}
                <p>{product.tagline}</p>
            </Link>
        </div>
        <ReactTooltip delayShow={300} effect="solid" />
    </div>
);

export default Product;
