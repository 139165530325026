/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 14/06/2016
 * Time: 15:23
 */

const Helpers = {
    guid() {
        return Math.floor(Math.random() * 1000000);
    }
};

export default Helpers;
