/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 09/07/2017
 * Time: 16:25
 */
import * as types from '../constants/actionTypes';

// ==================================================================
// SET MEDIA=========================================================
// ==================================================================

export function setMedia(media) {
    return {
        type: types.SET_MEDIA,
        media
    };
}
