/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 08/07/2017
 * Time: 8:09
 */
import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { DiscussionEmbed, CommentCount } from 'disqus-react';
import MetaTags from 'react-meta-tags';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    DownloadCloud,
    LifeBuoy,
    MessageCircle,
    Package,
    RefreshCcw,
    Facebook,
    Twitter,
    FileText
} from 'react-feather';
import actions from '../../actions';
import Checkout from '../Partials/Checkout';

class Single extends React.Component {
    componentDidMount() {
        console.log(this.props);
        const { setSingleProduct, match } = this.props;
        setSingleProduct(match.params.slug);
    }

    componentWillUnmount() {
        const { setSingleProduct } = this.props;
        setSingleProduct();
    }

    render() {
        const { products } = this.props;
        const { product } = products;
        const url = `${window.location.protocol}//${window.location.host}${
            window.location.pathname
        }`;

        const disqusConfig = {
            url,
            identifier: product.slug,
            title: 'We are here for you'
        };

        const disqusShortname = 'digital-awesome';
        return (
            <div className="single">
                <MetaTags>
                    <title>{`${product.name} | Digital Awesome`}</title>
                </MetaTags>
                <div className="hero-single">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <div className="hero-single-content row center-xs middle-xs">
                                    <div className="actions-single text-left">
                                        <h1 className="product-name">
                                            {product.name}
                                        </h1>
                                        <h3>{product.tagline}</h3>
                                        <div className="buttons row around-xs middle-xs">
                                            <Link
                                                to={`/preview/${product.slug}`}
                                            >
                                                <button
                                                    className="btn btn-default"
                                                    type="button"
                                                >
                                                    Live Demo
                                                </button>
                                            </Link>
                                            <Checkout {...this.props} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 first-sm">
                                <div className="hero-single-image row center-xs middle-xs">
                                    <img
                                        className="image-single shadow-2 img-responsive"
                                        src={product.image}
                                        alt={product.name}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info-single">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-9">
                                <div className="description-single square">
                                    <div className="row">
                                        <div className="col-sm-8 col-sm-offset-1">
                                            <h2>{product.name}</h2>
                                            <h4>{product.tagline}</h4>
                                            <p className="description-text">
                                                {product.description}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="more-info col-sm-10 col-sm-offset-1">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <p className="docs">
                                                        {product.docs
                                                            !== '' && (
                                                            <a
                                                                href={
                                                                    product.docs
                                                                }
                                                            >
                                                                <FileText />
                                                                {' '}
                                                                <span>
                                                                    Read the
                                                                    documentation
                                                                </span>
                                                            </a>
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="col-sm-6">
                                                    <p className="share">
                                                        Share:
                                                        <a
                                                            href={`https://www.facebook.com/sharer/sharer.php?u=${
                                                                window.location
                                                                    .href
                                                            }`}
                                                        >
                                                            <Facebook />
                                                        </a>
                                                        <a
                                                            href={`https://twitter.com/home?status=${
                                                                window.location
                                                                    .href
                                                            }`}
                                                        >
                                                            <Twitter />
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-3">
                                <div className="features-single square">
                                    <div className="row around-xs">
                                        <span data-tip="HTML 5">
                                            <img
                                                src="/images/html.png"
                                                alt="html 5"
                                            />
                                        </span>
                                        <span data-tip="CSS 3">
                                            <img
                                                src="/images/css.png"
                                                alt="css 3"
                                            />
                                        </span>
                                        <span data-tip="Sass">
                                            <img
                                                src="/images/sass.png"
                                                alt="sass"
                                            />
                                        </span>
                                        <span data-tip="Bootstrap 4">
                                            <img
                                                src="/images/bootstrap.png"
                                                alt="bootstrap"
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="stats-single square">
                                    <div className="stats-single-content">
                                        <div className="stat-single row between-xs middle-xs">
                                            <DownloadCloud />
                                            <span>
                                                {product.downloads}
                                                {' '}
Downloads
                                            </span>
                                        </div>
                                        <div className="stat-single row between-xs middle-xs">
                                            <Package />
                                            <span>
                                                Released:
                                                {' '}
                                                {Moment(
                                                    product.created_at
                                                ).fromNow()}
                                            </span>
                                        </div>
                                        <div className="stat-single row between-xs middle-xs">
                                            <RefreshCcw />
                                            <span>
                                                Updated:
                                                {' '}
                                                {Moment(
                                                    product.updated_at
                                                ).fromNow()}
                                            </span>
                                        </div>
                                        <div className="stat-single row between-xs middle-xs">
                                            <MessageCircle />
                                            <CommentCount
                                                shortname={disqusShortname}
                                                config={disqusConfig}
                                            />
                                        </div>
                                        <div className="stat-single row between-xs middle-xs">
                                            <LifeBuoy />
                                            <span>
                                                {product.support}
                                                {' '}
Support
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="full-screen-single">
                    <div className="container">
                        <div className="square">
                            <img
                                className="img-responsive"
                                src={product.full_screen}
                                alt="full-screen"
                            />
                        </div>
                    </div>
                </div>
                <div className="comments-single">
                    <div className="container">
                        <div className="square">
                            <h2 className="text-center">We are here for you</h2>
                            <DiscussionEmbed
                                shortname={disqusShortname}
                                config={disqusConfig}
                            />
                        </div>
                    </div>
                </div>
                <ReactTooltip delayShow={300} effect="solid" />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    products: state.products
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Single);
