/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 09/07/2017
 * Time: 16:27
 */
import * as types from '../constants/actionTypes'

const defaultState = {
    media: "desktop"
}

function preview(state = defaultState, action) {
    switch (action.type) {

        case types.SET_MEDIA:
            return {
                ...state,
                media: action.media
            }

        default:
            return state
    }
}

export default preview