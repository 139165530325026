import axios from 'axios';
import * as constants from '../constants/config';

const callApi = async (method, path, data = null) => {
    const url = `${constants.SERVER_URL}/api/${path}`;
    const response = await axios({
        method,
        url,
        data
    });

    return response.data;
};

export const get = path => callApi('GET', path);

export const post = (path, data) => callApi('POST', path, data);

export const put = (path, data) => callApi('PUT', path, data);

export const del = path => callApi('DELETE', path);
