/**
 * Created by Benzo Media.
 * http://www.benzomedia.com
 * User: Oren Reuveni
 * Date: 14/07/2017
 * Time: 15:56
 */
import React from 'react';
import Rodal from 'rodal';
import classNames from 'classnames';

class EnterEmail extends React.Component {
    constructor() {
        super();

        this.state = {
            email: '',
            required: false
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { email } = this.state;
        const { onToken } = this.props;
        if (email === '') {
            this.setState({ required: true });
            return;
        }
        onToken({ email });
    };

    render() {
        const { email, required } = this.state;
        const { cart, toggleEnterEmail } = this.props;
        return (
            <div>
                <Rodal
                    visible={cart.enterEmailOpen}
                    onClose={() => toggleEnterEmail(false)}
                    width={640}
                    height={340}
                    customStyles={{ borderRadius: 10 }}
                    customMaskStyles={{
                        backgroundColor: 'rgb(24, 39, 56)',
                        opacity: 0.85
                    }}
                >
                    <div>
                        <div className="thankyou">
                            <div className="thankyou-header">
                                <h1>Get Ready!</h1>
                            </div>
                            <div className="enter-email-content text-center">
                                <h2>Please enter your email:</h2>
                                <form>
                                    <div className="form-group">
                                        <input
                                            value={email}
                                            className={classNames({
                                                required
                                            })}
                                            onChange={e => this.setState({
                                                email: e.target.value,
                                                required: false
                                            })
                                            }
                                            required
                                            type="email"
                                            placeholder="Your Email"
                                        />
                                    </div>
                                    <button
                                        onClick={this.handleSubmit}
                                        type="submit"
                                        className="btn btn-full"
                                    >
                                        Submit
                                    </button>
                                </form>
                            </div>
                            <div className="thankyou-footer text-center">
                                <p>hello@digitalaweso.me</p>
                                <h5>Digital Awesome Team</h5>
                            </div>
                        </div>
                    </div>
                </Rodal>
            </div>
        );
    }
}

export default EnterEmail;
